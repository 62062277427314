<template>
  <div style="text-align: justify">
    <p class="mb-6">
      Use the proper graphing method (see the Graphing Techniques in your lab manuals for more
      information regarding graphing), including metric graph paper, title, labeling of axes, units
      and scaling, to plot a graph of temperature versus time of the following data:
    </p>

    <v-simple-table style="margin-left: 15px; margin-bottom: 20px; width: 350px">
      <tbody>
        <tr>
          <td style="vertical-align: middle">
            <stemble-latex content="$\text{Temperature (}^{\circ}\text{C)}$" />
          </td>
          <td style="vertical-align: middle">
            <stemble-latex content="$\text{Time (minutes)}$" />
          </td>
        </tr>
        <tr v-for="[temp, time] in TEMPERATURE_DATA" :key="temp">
          <td style="vertical-align: middle; text-align: center">
            <latex-number :number="temp" />
          </td>
          <td style="vertical-align: middle; text-align: center">
            <latex-number :number="time" />
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import LatexNumber from '../displayers/LatexNumber';

const TEMPERATURE_DATA = [
  ['30', '6'],
  ['35', '8'],
  ['40', '10'],
  ['45', '12'],
  ['50', '14'],
];

export default {
  name: 'UPEICalorimetryPreLabPt2',
  components: {
    LatexNumber,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data: () => ({
    TEMPERATURE_DATA,
  }),
};
</script>
